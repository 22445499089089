<template>
  <el-dialog
      title="Sửa thông tin lớp học"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="110px"
        :model="editClass"
        label-position="left"
        :rules="rules"
        ref="editClass"
    >
      <!-- chọn khối -->
      <el-form-item label="Khối học">
        <el-input disabled v-model="editClass.grade.gradeName"></el-input>
      </el-form-item>

      <el-form-item label="Tên lớp" prop="className">
        <el-input
            v-model="editClass.className"
            @keyup.enter.native="submitForm('editClass')"
            placeholder="Nhập tên lớp"
        ></el-input>
        <span class="error-message">{{ customErrorMessages.newNameKt }}</span>
      </el-form-item>

      <el-form-item label="Mô tả" prop="classDescription">
        <el-input
            :rows="1"
            type="textarea"
            autosize
            v-model="editClass.classDescription"
            placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          v-if="showSaveChange"
          @click="submitForm('editClass')"
      >
        <i class="el-icon-circle-check" />
        <span>Lưu</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialogByButton('editClass')">
        <i class="el-icon-circle-close" />
        <span>Đóng</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import MaClassService from "@/services/MaClassService";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";
export default {
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      editClass: {
        id: "",
        className: "",
        classDescription: "",
        grade:{}
      },
      classList: [],
      className: "",
      customErrorMessages: {
        newNameKt: ""
      },
      showSaveChange: false,
      gradeList: [],
      editClassCreated: null,
      loadingButton: false,
      rules: {
        className: [
          {
            validator: this.changeClassName,
            trigger: "change"
          },
        ],
        idGrade: [
          {
            required: true,
            message: "Khối không được để trống",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    //reset when click x
    closeDialog() {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      setTimeout(() => {
        this.$refs["editClass"].resetFields();
      }, 500);
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      setTimeout(() => {
        this.$refs[fromData].resetFields();
      }, 500);
      this.$emit("dialog-close");
    },
    submitForm(fromData) {
      this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          MaClassService.update(this.editClass)
              .then((response) => {
                this.editClassCreated = response.data.data;
                this.$message({
                  message: "Cập nhật lớp thành công",
                  type: "success",
                });
                setTimeout(() => {
                  this.closeDialogByButton(fromData);
                }, 500);
              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              })
              .finally(() => {
                setTimeout(() => {
                  this.loadingButton = false;
                }, 500);
                // this.$emit("updateMaClass");
              });
        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },
   async getClassByIdMethod(idClass) {
      await MaClassService.findClassNewById(idClass)
          .then((resp) => {
            this.editClass = resp.data.data;
            this.className = this.editClass.className
          })
          .catch((err) => {
            console.log(err);
          });

      await financeKidsV2Service
          .getClassInGrade(parseInt(this.editClass.grade.id))
          .then((resp) => {
            resp.data && resp.data.data ? this.classList = resp.data.data : this.classList = []
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getDataInitial(idClass) {
      this.getClassByIdMethod(idClass);
    },

    changeClassName(rule, value, callback){
      let index = -1
      if (this.classList.length !== 0){
        for (let i = 0; i < this.classList.length; i++) {
          if (this.className === this.classList[i].className){
            index = i
          }
        }
        if (index > -1){
          this.classList.splice(index,1)
        }
        for (let i = 0; i < this.classList.length ; i++) {
          if (!value) {// Kiểm tra nếu giá trị rỗng
            this.customErrorMessages.newNameKt = "Giá trị không được để trống!";
            this.showSaveChange = false
            break
          }else  if (value.replace(/\s/g, "") === this.className.replace(/\s/g, "")){
            this.customErrorMessages.newNameKt = "Tên lớp không có sự thay đổi!";
            this.showSaveChange = false
            break
          }else  if (value.toLowerCase().replace(/\s/g, "") === this.classList[i].className.toLowerCase().replace(/\s/g, "")) {
            this.customErrorMessages.newNameKt = "Tên lớp: " + this.classList[i].className + " đã tồn tại trong khối!";
            this.showSaveChange = false
            break
          } else {
            this.showSaveChange = true
            this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
            callback();
          }
        }
      }else {
        this.showSaveChange = true
        this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner,
/deep/.el-textarea__inner {
  border: none;
  border-radius: 0;
  resize: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #d9d9d9;
}
/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-dialog__footer {
  padding-top: 0;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #606266;
  background: #e7e9ec;
  border-bottom: 1px solid #dcdfe6;
}
.select-grade {
  width: 100%;
}

.error-message {
  color: red;
}
</style>
