<template>
  <el-dialog
      title="Thêm mới lớp học"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
  >
    <el-form
        label-width="110px"
        :model="newClass"
        label-position="left"
        :rules="rules"
        ref="newClass"
    >
      <!-- chọn khối -->
      <el-form-item label="Chọn khối" prop="idGrade">
        <el-select
            class="select-grade"
            v-model="newClass.idGrade"
            placeholder="Chọn khối"
        >
          <el-option
              v-for="item in gradeOfSchoolList"
              :key="item.id"
              :value="item.id"
              :label="item.gradeName"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Tên lớp học" prop="className">
        <el-input
            :disabled="newClass.idGrade === ''"
            v-model="newClass.className"
            @keyup.enter.native="submitForm('newClass')"
            placeholder="Nhập tên lớp"
        ></el-input>
        <span class="error-message">{{ customErrorMessages.newNameKt }}</span>
      </el-form-item>

      <el-form-item label="Mô tả" prop="classDescription">
        <el-input
            :rows="1"
            type="textarea"
            autosize
            v-model="newClass.classDescription"
            placeholder="Nhập mô tả"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
       <el-button
           type="success"
           size="medium"
           :loading="loadingButton"
           mini
           v-if="showSaveChange"
           @click="submitForm('newClass')"
       >
        <i class="el-icon-circle-check"/>
        <span>Lưu</span>
      </el-button>
      <el-button type="danger" size="medium" @click="closeDialogByButton('newClass')">
        <i class="el-icon-circle-close"/>
        <span>Đóng</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import MaClassService from "@/services/MaClassService";
import {mapActions, mapState} from "vuex";
import financeKidsV2Service from "@/services/Finance_Kids_V2/FinanceKidsV2Service";

export default {
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      newClass: {
        className: "",
        classDescription: "",
        idGrade: "",
      },
      classList: [],
      customErrorMessages: {
        newNameKt: ""
      },
      showSaveChange: false,
      // gradeList: [],
      newClassCreated: null,
      loadingButton: false,
      rules: {
        className: [
          {
            validator: this.changeClassName,
            trigger: "change"
          },
        ],
        idGrade: [
          {
            required: true,
            message: "Khối không được để trống",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.$store.dispatch('gradeModule/fetchDataGradeOfSchoolList');
  },
  computed: {
    ...mapState('gradeModule', ['gradeOfSchoolList']),
  },
  methods: {
    ...mapActions('gradeModule', ['fetchDataGradeOfSchoolList']),
    ...mapActions('classStore', ['fetchDataClassOfGradeList']),
    //reset when click x
    closeDialog() {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      this.newClass.idGrade = ""
      this.newClass.className = ""
      this.newClass.classDescription = ""
      this.$emit("dialog-close");
      this.$refs["newClass"].resetFields();
    },
    async changeClassName(rule, value, callback){
     await financeKidsV2Service
          .getClassInGrade(parseInt(this.newClass.idGrade))
          .then((resp) => {
            resp.data && resp.data.data ? this.classList = resp.data.data : this.classList = []
          })
          .catch((err) => {
            console.log(err);
          });
     if (this.classList.length !== 0){
       for (let i = 0; i < this.classList.length ; i++) {
         if (!value) {// Kiểm tra nếu giá trị rỗng
           this.customErrorMessages.newNameKt = "Giá trị không được để trống!";
           this.showSaveChange = false
           break
         }else  if (value.toLowerCase().replace(/\s/g, "") === this.classList[i].className.toLowerCase().replace(/\s/g, "")){
           this.customErrorMessages.newNameKt = "Tên lớp: "+this.classList[i].className + " đã tồn tại trong khối!";
           this.showSaveChange = false
           break
         }else {
           this.showSaveChange = true
           this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
           callback();
         }
       }
     }else {
       this.showSaveChange = true
       this.customErrorMessages.newNameKt = ""; // Xóa thông báo lỗi tùy chỉnh
       callback();
     }

    },
    //click when click button
    closeDialogByButton() {
      this.showSaveChange = false
      this.customErrorMessages.newNameKt = "";
      this.newClass.idGrade = ""
      this.newClass.className = ""
      this.newClass.classDescription = ""
      this.$emit("dialog-close");
    },
    resetFormData() {
      this.$refs["newClass"].resetFields();
    },
    async submitForm(fromData) {
      await this.$refs[fromData].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          MaClassService.create(this.newClass)
              .then((response) => {

                this.newClassCreated = response.data.data;
                this.$message({
                  message: "Thêm mới lớp thành công",
                  type: "success",
                });

              })
              .catch((err) => {
                this.$message({
                  message: err.response.data.message,
                  type: "error",
                });
              }).finally(()=>{
            this.closeDialog()
            this.loadingButton = false;
          })

        } else {
          console.log("error validate data!");
          return false;
        }
      });
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner,
/deep/ .el-textarea__inner {
  border: none;
  border-radius: 0;
  resize: none;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid #d9d9d9;
}

/deep/ .el-dialog__title {
  font-size: 25px;
  color: #606266;
}

/deep/ .el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/ .el-dialog__footer {
  padding-top: 0;
}

.select-grade {
  width: 100%;
}

.error-message {
  color: red;
}
</style>
