var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm mới lớp học",
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "newClass",
          attrs: {
            "label-width": "110px",
            model: _vm.newClass,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Chọn khối", prop: "idGrade" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-grade",
                  attrs: { placeholder: "Chọn khối" },
                  model: {
                    value: _vm.newClass.idGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.newClass, "idGrade", $$v)
                    },
                    expression: "newClass.idGrade",
                  },
                },
                _vm._l(_vm.gradeOfSchoolList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.gradeName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tên lớp học", prop: "className" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.newClass.idGrade === "",
                  placeholder: "Nhập tên lớp",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.submitForm("newClass")
                  },
                },
                model: {
                  value: _vm.newClass.className,
                  callback: function ($$v) {
                    _vm.$set(_vm.newClass, "className", $$v)
                  },
                  expression: "newClass.className",
                },
              }),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.customErrorMessages.newNameKt)),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả", prop: "classDescription" } },
            [
              _c("el-input", {
                attrs: {
                  rows: 1,
                  type: "textarea",
                  autosize: "",
                  placeholder: "Nhập mô tả",
                },
                model: {
                  value: _vm.newClass.classDescription,
                  callback: function ($$v) {
                    _vm.$set(_vm.newClass, "classDescription", $$v)
                  },
                  expression: "newClass.classDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.showSaveChange
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("newClass")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("newClass")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }