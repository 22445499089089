var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Đổi khối cho lớp",
        visible: _vm.dialogVisible,
        width: "550px",
        "before-close": _vm.closeDialogByButton,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "110px",
            model: _vm.dataInput,
            "label-position": "left",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "Tên khối" } }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.dataInput.gradeName))]),
          ]),
          _c("el-form-item", { attrs: { label: "Tên lớp" } }, [
            _c("span", [_vm._v(" " + _vm._s(_vm.dataInput.className))]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn khối", prop: "idGrade" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-grade",
                  attrs: { placeholder: "Chọn khối", clearable: "" },
                  on: { change: _vm.changeClassName },
                  model: {
                    value: _vm.dataInput.idGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "idGrade", $$v)
                    },
                    expression: "dataInput.idGrade",
                  },
                },
                _vm._l(_vm.gradeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.gradeName },
                  })
                }),
                1
              ),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.customErrorMessages.newNameKt)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.showSaveChange
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "success",
                    size: "medium",
                    loading: _vm.loadingButton,
                    mini: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-check" }),
                  _c("span", [_vm._v("Lưu")]),
                ]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }